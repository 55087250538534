import React, { useEffect } from "react";
import { Counter } from "./features/counter/Counter";
import Container from "react-bootstrap/Container";
import { Amplify } from "aws-amplify";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home.page";
import AuthenticationPage from "./pages/authentication.page";
import TermsOfService from "./pages/terms-of-service.page";
import PrivacyPolicy from "./pages/privacy-policy.page";
import Navigation from "./components/Navigation";
import AddEventPage from "./pages/addEvent.page";

function App() {
  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      },
    });
  });
  return (
    <Container>
      <Navigation />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="signin" element={<AuthenticationPage />} />
        <Route path="counter" element={<Counter />} />
        <Route path="addEvent" element={<AddEventPage />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Container>
  );
}

export default App;
