import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "./userApi";
import { RootState } from "../../app/store";
import { FetchError, SignUpErrors } from "./User.errors";

export interface UserState {
  name?: string;
  email?: string;
  status: "idle" | "failed" | "await code" | "logged_in";
  error?: SignUpErrors;
  isLoading: boolean;
  jwtToken?: any;
  refreshToken?: string;
}

const initialState: UserState = {
  status: "idle",
  isLoading: false,
};

export const signUpAsync = createAsyncThunk(
  "user/signUp",
  async ({ email, password }: any) => {
    await api.signUp(email, password);
  }
);

export const signInAsync = createAsyncThunk(
  "user/signIn",
  async ({ email, password }: any) => {
    return await api.signIn(email, password);
  }
);

export const signInWithGoogle = createAsyncThunk(
  "user/signInWithGoogle",
  async () => {
    return await api.signInWithGoogle();
  }
);

export const signOutAsync = createAsyncThunk("user/signOut", async () => {
  await api.signOut();
});

export const confirmCodeAsync = createAsyncThunk(
  "user/confirmCode",
  async ({ email, code }: any) => {
    await api.ConfirmSignUp(email, code);
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signUpAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
    });
    builder.addCase(signUpAsync.fulfilled, (state) => {
      state.status = "await code";
      state.error = undefined;
      state.isLoading = false;
    });
    builder.addCase(signUpAsync.rejected, (state, action) => {
      state.status = "failed";
      state.error = FetchError(action.error);
      state.isLoading = false;
    });
    builder.addCase(confirmCodeAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
    });
    builder.addCase(confirmCodeAsync.fulfilled, (state) => {
      state.status = "idle";
      state.error = undefined;
      state.isLoading = false;
    });
    builder.addCase(confirmCodeAsync.rejected, (state, action) => {
      state.status = "failed";
      state.error = FetchError(action.error);
      state.isLoading = false;
    });
    builder.addCase(signInAsync.rejected, (state, action) => {
      state.status = "failed";
      state.error = FetchError(action.error);
      state.isLoading = false;
    });
    builder.addCase(signInAsync.pending, (state) => {
      state.status = "idle";
      state.isLoading = true;
    });
    builder.addCase(signInAsync.fulfilled, (state, action) => {
      state.status = "logged_in";
      state.error = undefined;
      state.isLoading = false;
      state.jwtToken = action.payload.jwtToken;
      state.email = action.payload.email;
      state.refreshToken = action.payload.refreshToken;
    });
    builder.addCase(signOutAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signOutAsync.fulfilled, (state) => {
      state.status = "idle";
      state.error = undefined;
      state.isLoading = false;
      state.jwtToken = undefined;
      state.email = undefined;
      state.refreshToken = undefined;
    });
  },
});

export const selectUser = (state: RootState) => state.user.name;
export const selectStatus = (state: RootState) => state.user.status;
export const getError = (state: RootState) => state.user.error;
export const getLoadingStatus = (state: RootState) => state.user.isLoading;

export default userSlice.reducer;
