import { Card, Col, Row } from "react-bootstrap";
import SignInComponent from "../features/user/SignIn.component";
import SignUpComponent from "../features/user/SignUp.component";

const AuthenticationPage = () => {
  return (
    <>
      <Row>
        <Col xs={12} lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Sign In</Card.Title>
              <SignInComponent />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Sign Up</Card.Title>
              <SignUpComponent />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AuthenticationPage;
