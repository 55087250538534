import { SerializedError } from "@reduxjs/toolkit";

export interface SignUpErrors {
  email?: Array<string>;
  password?: Array<string>;
  generic?: Array<string>;
}

export function FetchError(error: SerializedError): SignUpErrors {
  switch (error.code) {
    case "UsernameExistsException":
      return {
        email: [error.message ?? "User already exists"],
      };
    case "InvalidParameterException":
      return {
        email: [error.message ?? "Invalid parameter"],
      };
    case "InvalidPasswordException":
      return {
        password: [
          error.message ??
            "Please think about different password, this one is no good.",
        ],
      };
    case "CodeMismatchException":
      return { generic: [error.message ?? "Incorrect code"] };

    case "NotAuthorizedException":
      return {
        generic: [error.message ?? "Incorrect username or password."],
      };
    default:
      return {
        email: [
          "Not sure what just happened, but there is wired error. Please try again.",
        ],
        generic: [
          "Not sure what just happened, but there is wired error. Please try again.",
        ],
      };
  }
}
