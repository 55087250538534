const TermsOfService = () => {
  return (
    <>
      <h1>Terms of Service</h1>

      <p>
        Welcome to our application! By using our application, you agree to be
        bound by these terms of service. If you do not agree to these terms,
        please do not use our application.
      </p>

      <h2>Use of the Application</h2>

      <p>
        You must be 13 years of age or older to use our application. If you are
        under 13, you may not use the application without the consent of a
        parent or guardian. You are responsible for any activity that occurs
        under your account, whether or not you authorized the activity.
      </p>

      <p>
        You may not use our application for any illegal or unauthorized purpose.
        You must not, in the use of the application, violate any laws in your
        jurisdiction (including but not limited to copyright laws).
      </p>

      <h2>Modification of the Application</h2>

      <p>
        We reserve the right to modify or discontinue the application at any
        time, without notice to you. We will not be liable to you or any third
        party for any modification, suspension, or discontinuation of the
        application.
      </p>

      <h2>Termination of Use</h2>

      <p>
        We reserve the right to terminate your use of the application at any
        time, without notice to you. We will not be liable to you or any third
        party for any termination of your use of the application.
      </p>

      <h2>Intellectual Property</h2>

      <p>
        The content on the application, including but not limited to text,
        graphics, images, and software, is the property of our company or its
        licensors and is protected by copyright and other intellectual property
        laws. You may not use any content on the application for commercial
        purposes without the express written consent of our company.
      </p>

      <h2>Disclaimers</h2>

      <p>
        The application is provided on an "as is" and "as available" basis. We
        make no warranties, express or implied, as to the operation of the
        application or the information, content, materials, or products included
        on the application. We will not be liable for any damages of any kind
        arising from the use of the application, including but not limited to
        direct, indirect, incidental, punitive, and consequential damages.
      </p>

      <h2>Governing Law</h2>

      <p>
        These terms of service and your use of the application shall be governed
        by and construed in accordance with the laws of the State of Victoria,
        without giving effect to any principles of conflicts of law. Any legal
        action or proceeding arising under these terms of service or the
        application shall be brought exclusively in the federal or state courts
        located in Victoria.
      </p>

      <h2>Changes to the Terms of Service</h2>

      <p>
        We reserve the right to update these terms of service at any time. We
        will notify you of any changes by posting the new terms of service on
        the application. You are advised to review these terms of service
        periodically for any changes. Your continued use of the application
        after any changes to these terms of service are posted will be deemed
        acceptance of those changes.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions about these terms of service, please contact
        us at info@demosth.com.
      </p>
    </>
  );
};

export default TermsOfService;
