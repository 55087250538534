import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import SignUpPresentation from "./SignUp.presentation";
import {
  getError,
  signUpAsync,
  selectStatus,
  confirmCodeAsync,
  getLoadingStatus,
} from "./userSlice";
import ConfirmCodePresentation from "./ConfirmCode.presentation";
const SignUpComponent = () => {
  const [email, setEmail] = useState("");

  const dispatch = useAppDispatch();
  const errors = useAppSelector(getError);
  const status = useAppSelector(selectStatus);
  const loading = useAppSelector(getLoadingStatus);

  const submit = (email: string, password: string): void => {
    setEmail(email);
    dispatch(signUpAsync({ email, password }));
  };
  return (
    <>
      {status !== "await code" && (
        <SignUpPresentation
          errors={errors}
          loading={!!loading}
          handleSubmit={submit}
        />
      )}
      {status === "await code" && (
        <ConfirmCodePresentation
          loading={loading}
          handleSubmit={(code) => dispatch(confirmCodeAsync({ email, code }))}
        />
      )}
    </>
  );
};

export default SignUpComponent;
