import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

export function signUpOld(amount = 1) {
  return new Promise<{ data: string }>((resolve) =>
    setTimeout(() => resolve({ data: "John Smith" }), 500)
  );
}

export async function signUp(email: string, password: string): Promise<void> {
  await Auth.signUp({
    username: email,
    password,
    attributes: { email },
  });
}

export async function resendCode(email: string): Promise<void> {
  await Auth.resendSignUp(email);
}

export interface SignInResult {
  jwtToken: string;
  email: string;
  refreshToken: string;
}
export async function signIn(
  email: string,
  password: string
): Promise<SignInResult> {
  const signInResults = await Auth.signIn(email, password);
  return {
    jwtToken: signInResults.signInUserSession.idToken.jwtToken,
    email: signInResults.signInUserSession.idToken.payload.email,
    refreshToken: signInResults.signInUserSession.refreshToken.token,
  };
}

export async function signInWithGoogle(): Promise<void> {
  console.log("we got here");
  const result = await Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
  });

  console.log(result);
}

export async function signOut(): Promise<void> {
  await Auth.signOut();
}

export async function ConfirmSignUp(
  email: string,
  code: string
): Promise<void> {
  await Auth.confirmSignUp(email, code);
}

export async function getCurrentUser() {
  await Auth.currentUserInfo();
}
