import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SignInPresentation from "./SignIn.presentation";
import {
  getLoadingStatus,
  signInAsync,
  getError,
  signInWithGoogle,
} from "./userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

const SignInComponent = () => {
  const loading = useAppSelector(getLoadingStatus);
  const errors = useAppSelector(getError);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <SignInPresentation
      loading={loading}
      errors={errors}
      handleGoogleSignIn={async () => {
        await dispatch(signInWithGoogle());
      }}
      handleSubmit={async (email, password) => {
        const resultAction = await dispatch(signInAsync({ email, password }));
        const originalPromiseResult = unwrapResult(resultAction);
        if (originalPromiseResult.email) {
          navigate("/");
        }
      }}
    />
  );
};

export default SignInComponent;
