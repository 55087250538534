import Button, { ButtonProps } from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

interface ButtonAsyncProps extends ButtonProps {
  loading?: boolean;
}
const ButtonAsync = (props: ButtonAsyncProps) => {
  const { loading, ...rest } = props;

  return (
    <Button {...rest}>
      {props.loading && <Spinner animation="grow" size="sm" />} {props.children}
    </Button>
  );
};

export default ButtonAsync;
