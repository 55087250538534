const PrivacyPolicy = () => {
  return (
    <>
      <h1>Privacy Policy</h1>

      <p>Effective Date: 2022-12-26</p>

      <p>
        This privacy policy ("Policy") explains how Demosth ("us", "we", or
        "our") collects, uses, and shares information about you when you use our
        website www.demosth.com (the "Site").
      </p>

      <p>
        Please read this Policy carefully to understand our policies and
        practices regarding your information and how we will treat it. If you do
        not agree with our policies and practices, do not use the Site. By
        accessing or using the Site, you agree to this Policy. This Policy may
        change from time to time. Your continued use of the Site after we make
        changes is deemed to be acceptance of those changes, so please check the
        Policy periodically for updates.
      </p>

      <h2>1. Information We Collect and How We Collect It</h2>

      <p>
        We collect information about you in several ways when you use the Site.
      </p>

      <ul>
        <li>
          Information You Provide to Us: We may collect information that you
          provide directly to us when you use the Site, such as your name, email
          address, and other personal information.
        </li>
        <li>
          Automatic Information: We may collect information about you
          automatically when you use the Site, such as your IP address, device
          type, and browser type. We may also collect information about your
          location, usage data, and the pages you visit on the Site.
        </li>
        <li>
          Cookies and Other Tracking Technologies: We may use cookies and other
          tracking technologies to collect information about you when you use
          the Site. Cookies are small data files that are stored on your device
          when you visit a website. We use cookies to improve your experience on
          the Site, such as by remembering your login information and
          preferences. You can control the use of cookies at the individual
          browser level, but if you choose to disable cookies, it may limit your
          use of certain features or functions on the Site.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>

      <p>
        We use the information we collect about you to operate, maintain, and
        improve the Site, and to provide you with the services and features
        offered on the Site. We may also use your information to communicate
        with you, such as to send you updates or newsletters, or to respond to
        your inquiries.
      </p>

      <p>
        We may also use your information for research and analytics purposes, to
        personalize your experience on the Site, and to improve the quality of
        our services.
      </p>

      <h2>3. How We Share Your Information</h2>

      <p>
        We may share your information with third parties in the following
        circumstances:
      </p>

      <ul>
        <li>
          With Service Providers: We may share your information with service
          providers that perform certain functions or services on our behalf.
          These service providers are contractually prohibited from using your
          information for any purpose other than providing the requested
          service.
        </li>
        <li>
          With Law Enforcement or In Response to Legal Process: We may disclose
          your information to law enforcement or in response to a legal process
          if required to do so by law, or if we believe in good faith that such
          action is necessary to protect the rights, property, or safety of the
          Site, our users, or the public.
        </li>
        <li>
          With Affiliates: We may share your information with our affiliates for
          the purposes described in this Policy.
        </li>
        <li>
          With Business Partners: We may share your information with our
          business partners for the purposes described in this Policy.
        </li>
      </ul>
      <p>
        We may also share your information in connection with a merger,
        acquisition, or sale of all or part of our business.
      </p>
      <h2>4. Your Choices and Rights</h2>
      <p>
        You have the following choices and rights regarding your information:
      </p>
      <ul>
        <li>
          Opting Out of Communications: You may opt out of receiving promotional
          communications from us by following the unsubscribe instructions
          included in the communication.
        </li>
        <li>
          Accessing and Updating Your Information: You may access and update
          your personal information by contacting us at [Insert Contact
          Information].
        </li>
        <li>
          Requesting Deletion of Your Information: You may request that we
          delete your personal information by contacting us at [Insert Contact
          Information]. We will respond to your request in a reasonable
          timeframe and in accordance with applicable law.
        </li>
        <li>
          Complaints: If you have a complaint about our privacy practices,
          please contact us at [Insert Contact Information] and we will work to
          resolve the issue. You may also have the right to make a complaint to
          your local data protection authority.
        </li>
      </ul>
      <h2>5. Children's Privacy</h2>
      <p>
        The Site is not intended for children under the age of 18. We do not
        knowingly collect personal information from children under 18. If we
        become aware that we have collected personal information from a child
        under 18, we will take steps to delete such information as soon as
        possible.
      </p>
      <h2>6. Data Retention and Security</h2>
      <p>
        We will retain your information for as long as necessary to fulfill the
        purposes outlined in this Policy, unless a longer retention period is
        required or permitted by law.
      </p>
      <p>
        We take reasonable measures to protect your information from
        unauthorized access or disclosure. However, no system is completely
        secure, and we cannot guarantee the security of your information.
      </p>
      <h2>7. International Data Transfer</h2>
      <p>
        Your information may be transferred to and processed in countries other
        than the country in which you are resident. These countries may have
        data protection laws that are different from the laws of your country.
      </p>
      <p>
        We will protect your information in accordance with this Policy and
        applicable law, regardless of where it is processed or stored.
      </p>
      <h2>8. Changes to this Policy</h2>
      <p>
        We may update this Policy from time to time to reflect changes to our
        practices or for other operational, legal, or regulatory reasons. We
        encourage you to review the Policy periodically for the latest
        information on our privacy practices.
      </p>
      <h2>9. Contact Us</h2>
      <p>
        If you have any questions about this Policy or our privacy practices,
        please contact us at info@demosth.com.
      </p>
    </>
  );
};

export default PrivacyPolicy;
