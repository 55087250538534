import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { SignUpErrors } from "./User.errors";
import ButtonAsync from "../../components/ButtonAsync";

interface SignUpPresentationProps {
  handleSubmit?: (email: string, password: string) => void;
  errors?: SignUpErrors;
  loading?: boolean;
}

const SignUpPresentation = (props: SignUpPresentationProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Form noValidate>
      <fieldset disabled={props.loading}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={!!props.errors && !!props.errors.email}
          />
          <Form.Text>
            {props.errors &&
              props.errors.email &&
              props.errors.email.map((e, index) => (
                <Alert key={index} variant="warning">
                  {e}
                </Alert>
              ))}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            required
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            isInvalid={!!props.errors && !!props.errors.password}
          />
          <Form.Text>
            {props.errors &&
              props.errors.password &&
              props.errors.password.map((e, index) => (
                <Alert key={index} variant="warning">
                  {e}
                </Alert>
              ))}
          </Form.Text>
          <Form.Text>
            <h3>Password requirements</h3>
            <ul>
              <li>Contains at least 1 number</li>
              <li>Contains at least 1 special character</li>
              <li>Contains at least 1 uppercase letter</li>
              <li>Contains at least 1 lowercase letter</li>
            </ul>
          </Form.Text>
        </Form.Group>

        <ButtonAsync
          variant="primary"
          type="button"
          loading={!!props.loading}
          disabled={!email || !password || props.loading}
          onClick={() =>
            props.handleSubmit && props.handleSubmit(email, password)
          }
        >
          Sign Up
        </ButtonAsync>
      </fieldset>
    </Form>
  );
};

export default SignUpPresentation;
