import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonAsync from "../../components/ButtonAsync";
import { SignUpErrors } from "./User.errors";
interface SignInPresentationProps {
  handleSubmit?: (email: string, password: string) => void;
  handleGoogleSignIn: () => void;
  errors?: SignUpErrors;
  loading?: boolean;
}
const SignInPresentation = (props: SignInPresentationProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <Form noValidate>
      <fieldset>
        <Form.Group className="mb-3" controlId="formSignInEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="username@email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formSignInPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Text>
          {props.errors &&
            props.errors.generic &&
            props.errors.generic.map((e, index) => (
              <Alert key={index} variant="warning">
                {e}
              </Alert>
            ))}
        </Form.Text>
        <ButtonAsync
          variant="primary"
          type="button"
          loading={props.loading}
          disabled={!email || !password || props.loading}
          onClick={() =>
            props.handleSubmit && props.handleSubmit(email, password)
          }
        >
          Sign In
        </ButtonAsync>{" "}
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => props.handleGoogleSignIn()}
        >
          Sign in with google
        </Button>
      </fieldset>
    </Form>
  );
};

export default SignInPresentation;
