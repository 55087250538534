import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  selectStatus,
  signOutAsync,
  getLoadingStatus,
} from "../features/user/userSlice";
import ButtonAsync from "./ButtonAsync";

const Navigation = () => {
  const status = useAppSelector(selectStatus);
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getLoadingStatus);
  return (
    <>
      <Navbar bg="light" sticky="top">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            demosth
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link as={Link} to="/counter">
                Counter
              </Nav.Link>
              {status !== "logged_in" && (
                <Nav.Link as={Link} to="/signin">
                  Sign in
                </Nav.Link>
              )}
              {status === "logged_in" && (
                <ButtonAsync
                  variant="outline-secondary"
                  loading={loading}
                  onClick={() => {
                    dispatch(signOutAsync);
                  }}
                >
                  Sign Out
                </ButtonAsync>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br />
    </>
  );
};

export default Navigation;
