import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import ButtonAsync from "../../components/ButtonAsync";

interface ConfirmCodePresentationProps {
  loading?: boolean;
  handleSubmit?: (code: string) => Promise<any>;
  error?: string;
}
const ConfirmCodePresentation = (props: ConfirmCodePresentationProps) => {
  const [code, setCode] = useState("");

  return (
    <>
      <h1>We sent you an email with the code.</h1>
      <Form noValidate>
        <Form.Group className="mb-3" controlId="formCode">
          <Form.Label>Confirmation code</Form.Label>
          <Form.Control
            type="text"
            required
            value={code}
            onChange={(e) => setCode(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <ButtonAsync
          variant="primary"
          type="button"
          loading={props.loading}
          disabled={!code || props.loading}
          onClick={() => props.handleSubmit && props.handleSubmit(code)}
        >
          {" "}
          Sign Up
        </ButtonAsync>
      </Form>
    </>
  );
};
export default ConfirmCodePresentation;
